<template>
	<div>
		<div v-if="previewData != null && previewData != undefined && previewData != []">
			<!--{{previewData}}-->
			<!-- Image -->
			<div v-if="imageUrl != null && imageUrl != '' && imageUrl != undefined">
				<img :src="imageUrl" />
				<div>
					<ion-button size="small" color="danger" @click="removeImage()">Remove Image</ion-button>
				</div>
			</div>
			<div v-if="previewData.images != null && previewData.images != undefined && previewData.images != []" class="text-right">
				<ion-button size="small" color="light" @click="showAdditionalImages = !showAdditionalImages">
					<span v-if="showAdditionalImages === false">Show More Images</span>
					<span v-if="showAdditionalImages === true" class="text-danger">Close Images</span>
				</ion-button>
			</div>
			<!-- Additional Images -->
			<div v-if="showAdditionalImages === true" style="background:#000000;">
				<div v-if="previewData.images != null && previewData.images != undefined && previewData.images != []">
					<div v-for="(img, index) in previewData.images" class="link-image" :key="img" @click="updateCoverImage(index)">
						<ion-img :src="img"  />
					</div>
				</div>
			</div>
			<!-- Title -->
			<div v-if="previewData.title != null && previewData.title != undefined && previewData.title != ''" style="padding:20px;">
				<h4 style="padding:0px;margin:0px;">{{previewData.title}}</h4>
			</div>
			<!-- Description -->
			<div v-if="previewData.description != null && previewData.description != undefined && previewData.description != ''" style="padding: 0px 20px;">
				{{trimString(previewData.description, 72)}}
			</div>
		</div>
	</div>
</template>
<script>
import { IonImg, IonButton } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import apiClient from '../services/api';
import { useStore } from "vuex";

export default defineComponent( {
	components: { IonImg, IonButton },
	name: 'LinkPreview',
	props: ['urlToPreview'],
	setup() {
		const store = useStore();
		const previewData = ref(null);
		const url = ref(null);
		const showAdditionalImages = ref(false);
		const imageUrl = ref(null);

		function getPreview(url) {
			apiClient.post('/api/url/preview', 
				{
					url: url,        
				},
				{
					headers: {
						'Authorization':  `Bearer ${store.state.authToken}`
					}
				}).then(response => {
					console.log(response)
					if(response.status === 200) {
						previewData.value = response.data
						if(previewData.value) {
							if(previewData.value.cover != null && previewData.value.cover != undefined && previewData.value.cover != '') {
								imageUrl.value = previewData.value.cover
							}
						}
					}
				}).catch(error => {
					console.log(error)	
					// credentials didn't match
					if(error.response.status === 500) {
						console.log('500')
						imageUrl.value = null 
						//url.value = null
						previewData.value = null
					}	
				});
		}	

		function trimString(val, num) {
			if(val.length > num) {
				let trimmedString = val.substring(0, num)
				return trimmedString + '...'
			} else {
				return val
			}
		}

		function updateCoverImage(index) {
			if(previewData.value) {
				if(previewData.value.images != null && previewData.value.images != undefined && previewData.value.images != []) {
					imageUrl.value = previewData.value.images[index]
				}
			}
		}

		function removeImage() {
			imageUrl.value = null
		}

		function detectURLs(text) {
			var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
			//urls.value = text.match(urlRegex)
			console.log('test' +text.match(urlRegex))
		}

		return { url, getPreview, previewData, trimString, showAdditionalImages, updateCoverImage, imageUrl, removeImage, detectURLs }
	},
	methods: {
		dataUp: function() {
			if(this.previewData) {

				let linkData = {
					type_id: 'post',
					url: this.url,
					cover_image: this.imageUrl,
					title: this.previewData.title,
					description: this.previewData.description,
					external: 1
				}
				this.$emit('getLinkPreview', linkData)
			}
		}
	},
	watch: {
		urlToPreview: function() {
			if(this.urlToPreview != undefined && this.urlToPreview != null && this.urlToPreview != '') {
				this.url = this.urlToPreview
			} else {
				this.url = null
				this.imageUrl = null
				this.previewData = []
			}
		},
		url: function() {
			if(this.url != null) {
				this.getPreview(this.url)
				this.dataUp()
			}
		},
		previewData: {
			handler() {
				this.dataUp()	
			},
			deep:true
		},
		imageUrl: function() {
			this.dataUp()
			this.showAdditionalImages = false
		}
	}
});
</script>

<style scoped>
	.sm-padding-left {
		padding-left:15px;
	}
	.sm-padding-right {
		padding-right:15px;
	}
	.link-image {
		width:25%;
		display:inline-block;
	}
</style>