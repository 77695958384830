<template>
	<div>
		<LinkPreview :url-to-preview="url" @get-link-preview="getLinkData" />
		<!-- 
			URLs 
		-->
		<div v-if="urls != null">
			<div v-if="urls.length > 0" style="padding:20px 20px 5px 20px;">
				<div v-for="url in urls" :key="url" style="border-bottom:1px solid #eeeeee;">
					{{url}}
				</div>
			</div>
		</div>
		<!-- 
			Input 
		-->
		<div v-if="inputType == 'input'">
			<!-- Input -->
			<ion-item>
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-input style="background:#202020;" v-model="textString" @keydown="checkString($event)"></ion-input>
			</ion-item>
		</div>
		<!-- 
			Textarea
		-->
		<div v-if="inputType == 'textarea'">
			<!-- Textareat -->
			<ion-item>
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-textarea v-model="textString" @keydown="checkString($event)" placeholder="Type something..."></ion-textarea>
			</ion-item>
		</div>
		<!-- Matched Items -->
		<div v-if="triggered === true">
			<!-- Mention -->
			<div v-if="delimeter == '@'">
				<!-- Textarea -->
				<ion-item v-for="item in matchedItems" :key="item" @click.prevent="addItem(item)">
					<div tabindex="0"></div>
					<ion-avatar slot="start">
						<img :src="item.profilePhoto">
					</ion-avatar>
					<ion-label>
						<h4>
							{{item.name}}
						</h4>
						<p>{{delimeter}}{{item.username}}</p>
						<!--<p>St. Louis, MO</p>-->
					</ion-label>
				</ion-item>
			</div>
			<!-- Hashtag -->
			<div v-if="delimeter == '#'">
				<!-- Textarea -->
				<ion-item v-for="item in matchedItems" :key="item" @click.prevent="addItem(item)">
					<div tabindex="0"></div>
					<ion-label>
						<h4>{{item.hashtag}}</h4>
					</ion-label>
				</ion-item>
			</div>
		</div>
		<!-- Has Button -->
		<div v-if="textString != null && textString !=''">
			<div v-if="hasButton === true && stringValidated === false"> 
				<ion-text color="danger">Search must contain {{delimeter}}</ion-text>
			</div>
			<div v-if="hasButton === true && stringValidated === true"> 
				<ion-button @click.prevent="dataUp()">Search</ion-button>
			</div>
		</div>
	</div>
</template>

<script>
import { IonTextarea, IonInput, IonItem, IonLabel, IonButton, IonText, IonAvatar } from '@ionic/vue';
import { defineComponent, ref, onMounted } from 'vue';
import apiClient from '../services/api';
import validateUrl from '../services/validateUrl';
import LinkPreview from './LinkPreview.vue';

export default defineComponent( {
	components: { IonTextarea, IonInput, IonItem, IonLabel, IonButton, IonText, IonAvatar, LinkPreview },
	name: 'TextWrapper',
	props: ['inputType', 'isMentions', 'isHashtags', 'hasButton', 'setDelimeter', 'store', 'postBody'],
	setup(props) {
		const genericTrigger = ref(false);
		const textString = ref(null);
		const matchedItems = ref([]);
		const mentions = ref([]);
		const hashtags = ref([]);
		const showMatchList = ref(false);
		const mentionTriggered = ref(false);
		const hashTriggered = ref(false);
		const triggered = ref(false);
		const mentionString = ref(null);
		const hashString = ref(null);
		const delimeter = ref(null);
		const hashtagCollection = ref([]);
		const userCollection = ref([]);
		const stringValidated = ref(false);
		const urls = ref([]);
		const url = ref(null);
		const linkData = ref([]);

		onMounted(() => {
			if(props.store.genericTrigger === true) {
				genericTrigger.value = props.store.genericTrigger;
			}
			if(props.setDelimeter != null && props.setDelimeter != '' && props.setDelimeter != undefined) {
				delimeter.value = props.setDelimeter
			}
			if(props.isMentions === true) {
				getUserCollection()
			}
			if(props.isHashtags === true) {			
				getHashtagCollection()
			}
			if(props.postBody) {
				textString.value = props.postBody
			}
		})

		function getUserCollection() {
			apiClient.get('/api/get/profile/collection',
				{
					headers: {
						'Authorization':  `Bearer ${props.store.authToken}`
					}
				}).then(response => {
					for(var i=0;i<response.data.length;i++) {
						userCollection.value.push({
							id: response.data[i].id,
							name: response.data[i].name,
							username: response.data[i].username,
							profilePhoto: response.data[i].profile_photo
						})
					}
				}).catch(error => {
					console.log(error)
				});        
		}

		function getHashtagCollection() {
			apiClient.get('/api/get/hashtag/collection',
				{
					headers: {
						'Authorization':  `Bearer ${props.store.authToken}`
					}
				}).then(response => {
					for(var i=0;i<response.data.length;i++) {
						hashtagCollection.value.push({
							hashtag: response.data[i].hashtag,
						})
					}
				}).catch(error => {
					console.log(error)
				});        
		}

		function checkString(e) {
			var keyVal = null
			// Mention Triggered
			if(e.keyCode === 50) {
				keyVal = '@'
				mentionTriggered.value = true
			}
			// Hashtag Triggered
			if(e.keyCode === 51) {
				keyVal = '#'
				hashTriggered.value = true
			}
			// Release trigger
			if(e.keyCode === 32) {
				keyVal = ' '
				mentionTriggered.value = false
				hashTriggered.value = false
				mentionString.value = null
				hashString.value = null
			}
			// Do Mention
			if(mentionTriggered.value === true || hashTriggered.value === true) {
				// Backspace or Delete
				if(e.keyCode === 8 || e.keyCode === 46) {
					keyVal = ''
					// Backspace
					if(e.keyCode === 8) {
						// @
						if(mentionString.value != null) {
							mentionString.value = mentionString.value.slice(0, -1)
						}	
						// #
						if(hashString.value != null) {
							hashString.value = hashString.value.slice(0, -1)
						}						
					}
					// Delete
					if(e.keyCode === 46) {
						// @
						if(mentionString.value != null) {
							mentionString.value = mentionString.value.slice(0, 1)
						}	
						// #
						if(hashString.value != null) {
							hashString.value = hashString.value.slice(0, 1)
						}					
					}
				}
				// Filter out event key names - Shift / ArrowLeft / ArrowRight
				if(e.keyCode === 16 || e.keyCode === 37 || e.keyCode === 39) {
					keyVal = ''
				}

				if(keyVal === null) {
					keyVal = e.key
				}
				// Build mention string
				if(mentionTriggered.value === true) {
					if(mentionString.value === null) {
						mentionString.value = keyVal
					} else {
						mentionString.value = mentionString.value + keyVal
					}					
				}
				// Build hash string
				if(hashTriggered.value === true) {
					if(hashString.value === null) {
						hashString.value = keyVal
					} else {
						hashString.value = hashString.value + keyVal
					}					
				}				
				//console.log('Key:' + keyVal)				
			}
		}

		function searchUserCollection(str) {
			// Str is not empty
			matchedItems.value = []
			var origInput = null
			// Loop through the collection
			for(var i=0; i<userCollection.value.length;i++) {
				var username = '@'+userCollection.value[i].username.toLowerCase()
				str = str.toLowerCase()
				console.log(str)
				// Check if collection has
				if(username.includes(str)) { // includes may not be the right method here

					origInput = mentionString.value
					matchedItems.value.push({
						id: userCollection.value[i].id,
						name: userCollection.value[i].name,
						username: userCollection.value[i].username,
						profilePhoto: userCollection.value[i].profilePhoto,
						input: origInput								
					})

				}
			}
						
		}

		function searchHashtagCollection(str) {
			// Str is not empty
			matchedItems.value = []
			var origInput = null
			// Loop through the collection
			for(var i=0; i<hashtagCollection.value.length;i++) {
				var hashtag = '#'+hashtagCollection.value[i].hashtag.toLowerCase()
				str = str.toLowerCase()
				console.log(str)
				origInput = hashString.value
				// Check if collection has
				if(hashtag.includes(str)) { // includes may not be the right method here
					
					matchedItems.value.push({
						hashtag: hashtagCollection.value[i].hashtag,
						input: origInput								
					})

				} 
			}
						
		}

		function addItem(item) {
			var slug = null
			if(delimeter.value == '@') {
				slug = delimeter.value+item.username
				textString.value = textString.value.replace(item.input, slug+' ')
				mentions.value.push(slug)
				mentionTriggered.value = false
				mentionString.value = null
			}
			if(delimeter.value == '#') {

				textString.value = textString.value.replace(item.input, item.hashtag+' ')
				console.log(item.input+' '+item.hashtag)
				hashtags.value.push(item.hashtag)
				hashTriggered.value = false
				hashString.value = null
			}	
			mentions.value = []
			hashtags.value = []		
			matchedItems.value = []
			triggered.value = false
			this.dataUp()	
		}

		function detectURLs(text) {
			var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;

			if(text.match(urlRegex)) {
				urls.value = []
				url.value = null
				linkData.value = []
				let tempUrls = text.match(urlRegex)
				if(tempUrls) {
					
					for(var i=0;i<tempUrls.length;i++) {
						console.log(validateUrl(tempUrls[i]))
						if(validateUrl(tempUrls[i]) === true) {
							urls.value.push(tempUrls[i])
						} 
					}
				}
			}

		}

		function getLinkData(e) {
			if(e) {
				console.log('text wrapper')
				linkData.value = e
			}
		}

		return { textString, matchedItems, getUserCollection, getHashtagCollection, mentions, showMatchList, checkString, mentionTriggered, hashTriggered, mentionString, hashString, searchUserCollection, searchHashtagCollection, delimeter, triggered, addItem, hashtags, genericTrigger, userCollection, hashtagCollection, stringValidated, urls, detectURLs, url, linkData, getLinkData }
	},
	methods: {
		dataUp: function() {
			this.$emit('input-data', {
				mentions: this.mentions,
				hashtags: this.hashtags,
				text: this.textString,
				linkData: this.linkData
			})
		},
		stripHtml: function(html){
			let doc = new DOMParser().parseFromString(html, 'text/html');
			return doc.body.textContent || "";
		}
	},
	watch: {
		textString: function() {
			if(this.textString != null && this.textString != '' && this.textString != undefined) {
				this.detectURLs(this.textString)
			} else {
				this.linkData = []
				this.url = null
				this.urls = []
			}
			if(this.hasButton === true) {
				if(this.textString != null && this.textString != '') {
					
					if(this.textString.includes('#')) {
						this.stringValidated = true
					} else {
						this.stringValidated = false
					}
				}
			} else {
				this.dataUp()
			}
			if(this.genericTrigger === true) {
				//this.store.commit('setGenericTrigger', false)
			}
		},
		mentionString: function() {
			if(this.mentionString != null && this.mentionString != '') {
				this.triggered = true
				this.delimeter = '@'
				this.searchUserCollection(this.mentionString)
			} else {
				this.triggered = false
				this.mentionTriggered = false
			}
		},
		hashString: function() {
			if(this.hashString != null && this.hashString != '') {
				this.triggered = true
				this.delimeter = '#'
				this.searchHashtagCollection(this.hashString)
			} else {
				this.triggered = false
				this.hashTriggered = false
			}
		},
		genericTrigger: function() {
			if(this.genericTrigger === true) {
				this.textString = null
			}
		},
		urls: function() {
			if(this.urls) {
				if(this.urls.length > 0) {
					this.url = this.urls[0]
				}
			}
		},
		linkData: function() {
			this.dataUp()
		}

	}
});
</script>